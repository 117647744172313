// import WhyGetInvolved from '../../components/BestSchoolsAwards/WhyGetInvolved/WhyGetInvolved'
// import Categories from '../../components/BestSchoolsAwards/Categories/Categories'
import BSAContent from '../../components/BestSchoolsAwards/Content/Content'
import BSAFooter from '../../components/BestSchoolsAwards/Footer/Footer'
import ImageSlider from '../../components/BestSchoolsAwards/ImageSlider/ImageSlider'
import BSAShortHeader from '../../components/BestSchoolsAwards/Header/ShortHeader'
import React from 'react'
import { Helmet } from 'react-helmet'
// import * as ContentStyles from '../../components/BestSchoolsAwards/Content/Content.module.scss'
// import TestimonialSlider from '../../components/BestSchoolsAwards/TestimonialSlider/TestimonialSlider'

const BSATerms = () => {
  return (
    <>
      <Helmet>
        <title>
          Sponsor | Muddy Stilettos&apos; Best Schools Awards | Muddy Stilettos
        </title>
      </Helmet>
      <BSAShortHeader />
      <BSAContent>
        <div>
          <h1>Sponsor</h1>
          <p>
            The team at{' '}
            <a
              href="https://education.debretts.com/"
              target="_BLANK"
              rel="noreferrer"
            >
              Debrett’s Education
            </a>{' '}
            have seen at first hand the transformative power of a great
            education and are adept at dealing with all the ages and stages in a
            young person’s school career. They recognise that every child is
            different and what is right for one, may not be right for another,
            sometimes even in the same family.{' '}
          </p>
          <p>
            The UK Independent (Private) school system, with its rich cultural
            diversity and blend of long-standing traditions, excellent teaching
            and state of the art facilities, ranks as one of the best in the
            world.
          </p>
          <p>
            The consultants at Debrett’s Education have over 30 years of
            experience, as teachers, school leaders and parents, and their
            extensive knowledge, not only of the different schools but also
            their different curriculums, enables them to give the best, and more
            importantly, impartial advice, allowing parents to make the right
            choices and find the best fit for their child.
          </p>
        </div>
      </BSAContent>
      <ImageSlider />
      <BSAFooter />
    </>
  )
}

export default BSATerms
