import React, { useRef } from 'react'
import Slider from 'react-slick'
import * as LBBStyles from '../../layoutComponents/littleBlackBook/LittleBlackBook.module.scss'

const lBBImages = [
  {
    image: '/best-schools-awards/debretts/5.jpg'
  },
  {
    image: '/best-schools-awards/debretts/4.jpg'
  },
  {
    image: '/best-schools-awards/debretts/3.jpg'
  },
  {
    image: '/best-schools-awards/debretts/2.jpg'
  },
  {
    image: '/best-schools-awards/debretts/1.jpg'
  }
]

const slickSettings = {
  dots: false,
  infinite: true,
  autoplay: false,
  slidesToShow: 1,
  initialSlide: 0,
  centerMode: false
}

const ImageSlider = () => {
  const lbbSlider = useRef<Slider | null>(null)
  const moveCarousel = (index: number) => {
    lbbSlider.current?.slickGoTo(index)
  }

  return (
    <div>
      {lBBImages && (
        <div className={LBBStyles.Gallery}>
          <Slider {...slickSettings} ref={lbbSlider}>
            {lBBImages.map((image, index) => (
              <div key={index} className={LBBStyles.GalleryImage}>
                <img src={image?.image ?? ''} />
              </div>
            ))}
          </Slider>
          <div className={LBBStyles.Thumbnails}>
            {lBBImages.map((image, index) => (
              <div
                className={LBBStyles.Thumbnail}
                key={index}
                onClick={() => moveCarousel(index)}
              >
                {image?.image && <img src={image?.image} alt={``} />}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default ImageSlider
